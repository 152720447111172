import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isBasketDerivativeProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { getUnderlyingId } from '@/neos/business/rfq/strategy/leg/product/productSelector';

export function getUnderlyingUnit(
  state: AppState,
  productId: string,
  selectors: Selectors,
): string | undefined {
  const product = selectors.getProduct(state, productId);
  if (isBasketDerivativeProduct(product)) {
    return product.basketUnderlying.currency;
  }

  const underlyingId = getUnderlyingId(product);
  const underlyingInfo = selectors.getFullUnderlyingInfo(state, underlyingId);

  return underlyingInfo?.currency;
}
