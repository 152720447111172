import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { DeltaType, Way } from '../../../../../neos/business/neosModel';

export interface HedgeView {
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  deltaType: DeltaType | undefined;
  sgWay: Way | undefined;
  size: number | undefined;
  lotSizeMarket: string | undefined;
}

export interface DeltaSummaryModel {
  hedgeViews: HedgeView[];
  deltaSummaries: string[];
}

export function getDeltaSummaryModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): DeltaSummaryModel {
  const { getSortedHedges, getSortedDeltaSummaries } = selectors;
  const hedges = getSortedHedges(state, rfqId, selectors);
  const hedgeViews = hedges.map(
    ({ deltaType, sgWay, lotSize, market, negotiatedSize, underlyingId }): HedgeView => {
      const underlyingInfo = selectors.getFullUnderlyingInfo(state, underlyingId);
      const marketGalaxyCode = market?.galaxyCode;
      const micCode = market?.micCode;
      const lotSizeMarket =
        lotSize === undefined && marketGalaxyCode === undefined
          ? ''
          : `${lotSize ?? 'N/A'} | ${micCode ?? marketGalaxyCode ?? 'OTC'}`;
      return {
        underlyingId,
        bloombergCode: underlyingInfo?.bloombergCode,
        deltaType,
        sgWay,
        size: negotiatedSize?.numberOfLots,
        lotSizeMarket,
      };
    },
  );
  const deltaSummaries = getSortedDeltaSummaries(state, rfqId, selectors);

  return { hedgeViews, deltaSummaries };
}
