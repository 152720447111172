import type { Thunk } from '@/bootstrap/thunks.ts';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { exportDataToExcel } from '@/util/excel/excel.ts';
import {
  type EquityHedgeType,
  internalEquityHedgeTypes,
  otherEquityHedgeTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { clientWays } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';

export function createExportElsBuyAndSellCompositionThunk(rfqId: string, productId: string): Thunk {
  return function exportElsBuyAndSellCompositionThunk(_, getState, { selectors }) {
    const state = getState();
    const product = selectors.getProduct(state, productId);
    if (!isElsProduct(product) || !product.equityHedge) {
      return;
    }
    const { internal } = selectors.getRfqData(state, rfqId);
    const availableClients = selectors.getAndFormatAvailableClients(state, rfqId, selectors);

    const detailsWithUnderyingInfo = product.equityHedge.equityHedgeComponents
      .filter(hedgeComponents => hedgeComponents.underlyingId !== undefined)
      .map(hedgeComponents => {
        const underlyingInfo = selectors.getFullUnderlyingInfo(state, hedgeComponents.underlyingId);

        return {
          ...hedgeComponents,
          counterparty: availableClients.find(availableClient => {
            return availableClient.id === hedgeComponents.counterparty?.id;
          }),
          underlyingInfo,
        };
      });

    const exportedData = detailsWithUnderyingInfo.map(equityHedgeDetails => {
      const line: [string, string | number | undefined][] = [
        ['Bloomberg Code', equityHedgeDetails.underlyingInfo?.bloombergCode],
        ['Quantity', equityHedgeDetails.quantity],
        ['Spot', equityHedgeDetails.spot?.value],
        ['Spot Unit', equityHedgeDetails.spot?.unit],
        ['Spot Net', equityHedgeDetails.spotNet?.value],
        ['Spot Net Unit', equityHedgeDetails.spotNet?.unit],
        ['Nominal', equityHedgeDetails.nominal?.value],
        ['Nominal Unit', equityHedgeDetails.nominal?.unit],
        ['Counterpart', equityHedgeDetails.counterparty?.name],
        ['Broker', equityHedgeDetails.broker],
        ['Portfolio', equityHedgeDetails.portfolio],
        ['Type', getEquityTypeLabel(equityHedgeDetails.equityType)],
        ['Way', equityHedgeDetails.way && clientWays[equityHedgeDetails.way]],
      ];

      if (internal) {
        line.splice(8, 0, ['Internal Ptf', equityHedgeDetails.internalPortfolio]);
      }

      return Object.fromEntries(line);
    });

    exportDataToExcel(exportedData, 'Els Buy And Sell Composition');
  };
}

function getEquityTypeLabel(equityType: EquityHedgeType | undefined) {
  if (equityType === undefined) {
    return;
  }
  if (equityType === 'INTERNAL') {
    return internalEquityHedgeTypes[equityType];
  }
  return otherEquityHedgeTypes[equityType];
}
