import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getCurrencyByStrategyId(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): string | undefined {
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);
  if (products[0].hasCustomUnderlying) {
    const { quoteId } = selectors.getStrategyData(appState, strategyId);
    return selectors.getQuote(appState, quoteId)?.unit;
  }

  const underlyingId = selectors.getUnderlyingOrRefIdOfStrategy(appState, strategyId, selectors);
  const underlyingInfo = underlyingId && selectors.getFullUnderlyingInfo(appState, underlyingId);
  return underlyingInfo && underlyingInfo.currency;
}
