import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type AvailableStrikes,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../neos/business/neosModel';
import { getNearestElement } from '../strategy/leg/thunks/getNearestStrike';

export function getRfqProductNearestStrike(
  rfqId: string,
  appState: AppState,
  productId: string,
  { strikes }: AvailableStrikes,
  { getFullUnderlyingInfo, getReference, getProduct }: Selectors,
): number | undefined {
  let strike: number | undefined;

  const product = getProduct(appState, productId);

  const underlyingId = isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : undefined;
  if (!underlyingId) {
    strike = undefined;
  } else {
    const underlyingInfo = getFullUnderlyingInfo(appState, underlyingId);
    if (!underlyingInfo) {
      strike = undefined;
    } else {
      const reference = getReference(appState, { rfqId, underlyingId });

      if (
        underlyingInfo.type === 'INDEX' &&
        reference?.refLevel !== undefined &&
        reference?.refLevel !== 0
      ) {
        strike = getNearestElement(strikes, reference.refLevel);
      }

      if (
        underlyingInfo.type === 'STOCK' &&
        reference?.refSpot !== undefined &&
        reference?.refSpot !== 0
      ) {
        strike = getNearestElement(strikes, reference.refSpot);
      }
    }
  }

  return strike;
}
