import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqApplyImportedCompositionLegsThunk';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { last, sum, uniq } from 'lodash';
import {
  isSingleUnderlyingDerivativeProduct,
  isVSwapProduct,
} from '../../../../../../neos/business/neosModel';
import type { UnderlyingType } from '../../../../../../neos/business/neosOnyxModel';
import { getSizeUnit } from '../strategyDefinition/sizeCells/getSizeUnit';
import { getVolStrikeValue } from '@/neos/components/rfq/strategies/strategy/compositionLegsModal/quoteByClientWayHelpers.ts';

export interface CompositionLegsModalModel {
  title: string;
  compositionLegs: CompositionLeg[];
  underlyingTypes: UnderlyingType[];
  warningMessages: string[];
  missingDataWarningMessage: string | undefined;
  exportedContent: ImportedCompositionLegsData[];
  areSalesPricesDisplayed: boolean;
  columnTemplate: '1.25fr repeat(2, 1fr)' | '1.25fr repeat(4, 1fr)';
  displayCompoImportHasMultipleIndicesWarning: boolean;
  lastImportTime: number | undefined;
  lastImportRowCount: number | undefined;
}

export interface CompositionLeg {
  legId: string;
  productId: string;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  weight: number | undefined;
  size: number | undefined;
  sizeUnit: string | undefined;
  spot?: number;
  spotDisabled: boolean;
  expectedN: number | undefined;
  volStrike: number | undefined;
  quoteId: string | undefined;
  delta: number | undefined;
  canDeleteLeg: boolean;
}

export function getCompositionLegsModalModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  services: Services,
): CompositionLegsModalModel {
  const strategyData = selectors.getStrategyData(state, strategyId);
  const stratUi = selectors.getStrategyUi(state.ui, strategyId);
  const displayMissingDataWarning = stratUi?.displayMissingCompositionDataWarning;
  const displayCompoImportHasMultipleIndicesWarning =
    !!stratUi?.displayCompoImportHasMultipleIndicesWarning;

  const compositionLegIds = strategyData.legIds.slice(0, strategyData.legIds.length - 1);

  const indexLegId = last(strategyData.legIds)!;

  const underlyingTypes =
    selectors
      .getStrategyDefinition(state.referenceData, strategyData.strategyType)
      .legs.find(({ master }) => master)?.availableProductUnderlyingTypes ?? [];
  const title = strategyData.underlyingId
    ? `Top  ${strategyData.top} ${
        selectors.getFullUnderlyingInfo(state, strategyData.underlyingId)?.bloombergCode
      }`
    : 'Custom';
  const compositionLegs = compositionLegIds.map(legId =>
    getCompositionLeg(state, rfqId, strategyId, legId, selectors, services),
  );
  const indexLeg = getCompositionLeg(state, rfqId, strategyId, indexLegId, selectors, services);

  const areSalesPricesDisplayed = selectors.areSalesPricesDisplayed(state.ui, rfqId, selectors);
  return {
    underlyingTypes,
    compositionLegs,
    title,
    areSalesPricesDisplayed,
    warningMessages: getWarningMessages(compositionLegs, services),
    exportedContent: getCompositionLegsExportedContent(compositionLegs, indexLeg),
    columnTemplate: areSalesPricesDisplayed ? '1.25fr repeat(4, 1fr)' : '1.25fr repeat(2, 1fr)',
    missingDataWarningMessage: displayMissingDataWarning
      ? 'Some underlyings or weights may be missing in the imported file!'
      : undefined,
    displayCompoImportHasMultipleIndicesWarning,
    lastImportTime: stratUi?.compoLastImportTime,
    lastImportRowCount: stratUi?.compoLastImportRowCount,
  };
}

function getWarningMessages(compositionLegs: CompositionLeg[], services: Services): string[] {
  const messages: string[] = [];

  getSumWarnings();

  getUnderlyingsWarnings();

  return messages;

  function getUnderlyingsWarnings() {
    const uniqBloombergCode = uniq(
      compositionLegs.map(compositionLeg => compositionLeg.bloombergCode),
    );

    uniqBloombergCode.forEach(bloombergCode => {
      if (bloombergCode) {
        const occurences = compositionLegs.filter(
          compositionLeg => compositionLeg.bloombergCode === bloombergCode,
        ).length;
        if (occurences > 1) {
          messages.push(`${bloombergCode} is present more than once, please check.`);
        }
      }
    });
  }

  function getSumWarnings() {
    const weightsTotal = services.applyMultiplierWithPrecision(
      sum(compositionLegs.map(leg => leg.weight).filter(isDefined)),
    );
    if (weightsTotal !== 100) {
      messages.push(
        `The sum of all weights is equal to ${weightsTotal}% whereas it should be to 100%.`,
      );
    }
  }
}

function getCompositionLeg(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
  services: Services,
): CompositionLeg {
  const { clientWay } = selectors.getRfqData(appState, rfqId);
  const leg = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, leg.productId);
  const quote = selectors.getQuote(appState, leg.quoteId);
  const underlyingId = isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : undefined;
  const reference = underlyingId
    ? selectors.getReference(appState, { rfqId, underlyingId })
    : undefined;
  const bloombergCode = underlyingId
    ? selectors.getBloombergCode(appState, underlyingId, selectors)
    : undefined;

  const isReadOnlyRfq = selectors.isReadOnlyRfq(appState, rfqId);
  const uiSizeType = selectors.getDisplayNegotiatedSize(appState.ui, strategyId);
  const { notionalUnit = '', localNotionalUnit = '' } = leg;
  return {
    legId: leg.uuid,
    canDeleteLeg: !leg.isMaster,
    productId: leg.productId,
    underlyingId,
    bloombergCode,
    weight: services.applyMultiplierWithPrecision(leg.weight, 100, 6),
    size: leg[selectors.getLegSizeField(appState, strategyId, selectors)],
    spot: reference?.refSpot,
    spotDisabled: isReadOnlyRfq || !reference,
    expectedN: isVSwapProduct(product) ? product.expectedN : undefined,
    volStrike: getVolStrikeValue(clientWay, quote),
    quoteId: leg.quoteId,
    delta: quote.delta,
    sizeUnit: getSizeUnit(uiSizeType, { notionalUnit, localNotionalUnit }),
  };
}

function getCompositionLegsExportedContent(
  compositionLegs: CompositionLeg[],
  indexLeg: CompositionLeg,
): ImportedCompositionLegsData[] {
  return compositionLegs
    .map(c => ({ type: 'C', ...c }))
    .concat([{ type: 'I', ...indexLeg }])
    .map(({ bloombergCode, type, weight, expectedN, volStrike }) => ({
      underlying: bloombergCode,
      type,
      weight,
      expectedN,
      volStrike,
    }));
}
